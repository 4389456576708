import _ from 'lodash';
import { createSelector } from 'reselect';
export var getExperiments = function (state) { return state.experiments; };
export var experimentsFetched = createSelector(getExperiments, _.negate(_.isNil));
/**
 * @param {string} experimentName
 * @returns {Reselect.Selector} isEnabledSelector
 */
var createIsEnabledSelector = function (experimentName) {
    return createSelector(getExperiments, function (experiments) { return _.get(experiments, experimentName) === 'true'; });
};
export var isResponsiveEnabled = createIsEnabledSelector('specs.vod.responsive');
export var isResponsivePaymentViewsEnabled = createIsEnabledSelector('specs.vod.responsivePaymentViews');
