import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updatePremiumIntent } from 'shared/utils/premium-intent';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { getVodSettings } from 'shared/redux/server/actions/vod-settings/get';
import { getExperiments } from 'shared/redux/server/actions/experiments/get';
var PremiumIntentUpdater = /** @class */ (function (_super) {
    __extends(PremiumIntentUpdater, _super);
    function PremiumIntentUpdater() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isPremiumIntentUpdated = false;
        return _this;
    }
    PremiumIntentUpdater.prototype.componentDidMount = function () {
        var _a = this.props, getVodSettings = _a.getVodSettings, getExperiments = _a.getExperiments, isEditor = _a.isEditor;
        if (isEditor) {
            getVodSettings();
            getExperiments();
        }
    };
    PremiumIntentUpdater.prototype.UNSAFE_componentWillUpdate = function (nextProps) {
        var vodSettings = nextProps.vodSettings, experiments = nextProps.experiments;
        var isPremiumIntentUpdated = this.isPremiumIntentUpdated;
        if (isPremiumIntentUpdated ||
            _.isEmpty(experiments) ||
            _.isEmpty(vodSettings)) {
            return;
        }
        var biUserUploadedFiles = vodSettings.biUserUploadedFiles;
        updatePremiumIntent({
            hasUploadedVideos: biUserUploadedFiles,
        });
        this.isPremiumIntentUpdated = true;
    };
    PremiumIntentUpdater.prototype.render = function () {
        return null;
    };
    PremiumIntentUpdater.propTypes = {
        vodSettings: PropTypes.object,
        experiments: PropTypes.object,
        getVodSettings: PropTypes.func,
        getExperiments: PropTypes.func,
        isEditor: PropTypes.bool.isRequired,
    };
    PremiumIntentUpdater = __decorate([
        connect(function (state) { return ({
            vodSettings: state.vodSettings,
            experiments: state.experiments,
            isEditor: viewModeSelectors.isEditorMode(state),
        }); }, {
            getVodSettings: getVodSettings,
            getExperiments: getExperiments,
        })
    ], PremiumIntentUpdater);
    return PremiumIntentUpdater;
}(React.Component));
export default PremiumIntentUpdater;
