import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { updateDeeplink } from 'widget/utils/widget-info/widget-info';
import { getPublic as getChannel } from 'widget/redux/server/actions/channel/get-public';
import { getPaymentInfo } from 'widget/redux/server/actions/channel/payment';
import { resetSearch } from 'widget/redux/client/actions/search';
import { resetSelectedVideo } from 'widget/redux/client/actions/select-video';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { closeModal } from 'shared/components/modal/utils';
import { ViewModeObserver } from 'widget/containers/view-mode-observer';
import { loadMoreVideoPages } from 'widget/redux/client/lazy-channel-videos/actions';
import { getAllSettings, getVideoIdFromAppSettings, getChannelIdFromAppSettings, } from 'shared/selectors/app-settings';
import events from 'shared/constants/events';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { consumePubSubEvent, withPubSubEvents, } from 'widget/containers/pub-sub-events';
import WixEvents from 'shared/constants/wix-sdk';
var IGNORED_KEYS = ['previewType'];
var getChangedKeys = function (target, source) {
    var keys = _.union(_.keys(target), _.keys(source));
    return _.filter(keys, function (key) { return !_.includes(IGNORED_KEYS, key) && target[key] !== source[key]; });
};
var getAppSettingsDifference = function (props, nextProps) {
    var _a = nextProps.appSettings, nextBooleans = _a.booleans, nextNumbers = _a.numbers;
    var _b = props.appSettings, thisBooleans = _b.booleans, thisNumbers = _b.numbers;
    return {
        booleans: getChangedKeys(nextBooleans, thisBooleans),
        numbers: getChangedKeys(nextNumbers, thisNumbers),
    };
};
var hasChangesInAppSettings = function (props, nextProps) {
    var _a = getAppSettingsDifference(props, nextProps), booleans = _a.booleans, numbers = _a.numbers;
    return booleans.length || numbers.length;
};
var EditorObserver = /** @class */ (function (_super) {
    __extends(EditorObserver, _super);
    function EditorObserver() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleEditModeChange = function () {
            _this.reset();
            closeModal();
        };
        _this.reloadOnDashboardClosing = function (event) {
            if (event === events.DASHBOARD.CLOSED) {
                _this.reloadData();
                _this.reset();
            }
        };
        _this.updateWidgetChannel = function () {
            var _a = _this.props, currentChannelId = _a.currentChannelId, currentVideoId = _a.currentVideoId, isEditor = _a.isEditor;
            if (isEditor) {
                updateDeeplink(currentChannelId, currentVideoId);
            }
        };
        _this.reset = function () {
            var _a = _this.props, resetSearch = _a.resetSearch, resetSelectedVideo = _a.resetSelectedVideo, pauseVideo = _a.pauseVideo;
            pauseVideo();
            resetSearch();
            resetSelectedVideo();
        };
        return _this;
    }
    EditorObserver.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(WixEvents.SETTINGS_UPDATED, this.reloadOnDashboardClosing, {
            prevProps: prevProps,
            nextProps: this.props,
        });
        consumePubSubEvent(WixEvents.SITE_PUBLISHED, this.updateWidgetChannel, {
            prevProps: prevProps,
            nextProps: this.props,
        });
        var _a = this.props, currentChannelId = _a.currentChannelId, currentVideoId = _a.currentVideoId;
        if (currentChannelId !== prevProps.currentChannelId ||
            currentVideoId !== prevProps.currentVideoId) {
            this.updateWidgetChannel();
        }
    };
    EditorObserver.prototype.componentDidMount = function () {
        this.updateWidgetChannel();
    };
    EditorObserver.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        if (nextProps.isEditor && hasChangesInAppSettings(this.props, nextProps)) {
            this.reset();
        }
    };
    EditorObserver.prototype.reloadData = function () {
        var currentChannelId = this.props.currentChannelId;
        var _a = this.props, getChannel = _a.getChannel, getPaymentInfo = _a.getPaymentInfo, fetchVideos = _a.fetchVideos;
        getChannel(currentChannelId);
        getPaymentInfo(currentChannelId);
        fetchVideos();
    };
    EditorObserver.prototype.render = function () {
        return React.createElement(ViewModeObserver, { onChange: this.handleEditModeChange });
    };
    EditorObserver.propTypes = {
        currentChannelId: PropTypes.string.isRequired,
        currentVideoId: PropTypes.string,
        appSettings: PropTypes.object.isRequired,
        fetchVideos: PropTypes.func.isRequired,
        getChannel: PropTypes.func.isRequired,
        getPaymentInfo: PropTypes.func.isRequired,
        resetSearch: PropTypes.func.isRequired,
        resetSelectedVideo: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        isEditor: PropTypes.bool.isRequired,
    };
    EditorObserver = __decorate([
        withPubSubEvents,
        connect(function (state) { return ({
            currentChannelId: getChannelIdFromAppSettings(state),
            currentVideoId: getVideoIdFromAppSettings(state),
            appSettings: getAllSettings(state),
            isEditor: viewModeSelectors.isEditorMode(state),
        }); }, {
            getChannel: getChannel,
            getPaymentInfo: getPaymentInfo,
            resetSearch: resetSearch,
            resetSelectedVideo: resetSelectedVideo,
            pauseVideo: pauseVideo,
            fetchVideos: loadMoreVideoPages,
        })
    ], EditorObserver);
    return EditorObserver;
}(React.Component));
export { EditorObserver };
