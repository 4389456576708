import { __read, __spread } from "tslib";
export var wrapWithContext = function (fn) { return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return function (dispatch, getState, _a) {
        var withContext = _a.withContext;
        return withContext(fn).apply(void 0, __spread(args));
    };
}; };
