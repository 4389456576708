import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import getResizedImageUrl from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';
import classnames from 'classnames';
import { getNormalizedCachedDimensions } from '@wix/wix-vod-shared/dist/src/common/utils/normalize-image-dimensions';
import { withStyles } from 'shared/utils/withStyles';
import styles from './resized-image.styl';
var ResizedImage = /** @class */ (function (_super) {
    __extends(ResizedImage, _super);
    function ResizedImage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ResizedImage.prototype.getResizedImageUrl = function () {
        var _a = this.props, src = _a.src, width = _a.width, height = _a.height, params = _a.params, backgroundFit = _a.backgroundFit;
        var dimensions = getNormalizedCachedDimensions(src, {
            containerWidth: width,
            containerHeight: height,
        });
        var mode = backgroundFit ? 'fit' : undefined;
        return getResizedImageUrl({
            url: src,
            width: dimensions[0],
            height: dimensions[1],
            params: params,
            mode: mode,
        });
    };
    ResizedImage.prototype.render = function () {
        var _a;
        var _b = this.props, className = _b.className, width = _b.width, height = _b.height, style = _b.style, children = _b.children, dataHook = _b.dataHook, withFallbackColor = _b.withFallbackColor, backgroundFit = _b.backgroundFit, isMobile = _b.isMobile;
        var inlineStyle = __assign({ width: width && _.round(width), height: height && _.round(height) }, style);
        var resizedThumbnailUrl = this.getResizedImageUrl();
        if (resizedThumbnailUrl) {
            inlineStyle.backgroundImage = "url(" + resizedThumbnailUrl + ")";
        }
        var classNames = classnames(className, styles['resized-image'], (_a = {},
            _a[styles['with-fallback-color']] = withFallbackColor && !resizedThumbnailUrl,
            _a[styles['background-contain']] = backgroundFit,
            _a[styles.mobile] = isMobile,
            _a));
        return (React.createElement("div", { className: classNames, style: inlineStyle, "data-hook": dataHook, role: "presentation" }, children));
    };
    ResizedImage.propTypes = {
        src: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        params: PropTypes.object,
        style: PropTypes.object,
        className: PropTypes.string,
        children: PropTypes.node,
        dataHook: PropTypes.string,
        withFallbackColor: PropTypes.bool,
        backgroundFit: PropTypes.bool,
        isMobile: PropTypes.bool,
    };
    ResizedImage.defaultProps = {
        params: {},
        style: {},
    };
    ResizedImage = __decorate([
        withStyles(styles)
    ], ResizedImage);
    return ResizedImage;
}(React.Component));
export default ResizedImage;
