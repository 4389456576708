import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { getAllSettings } from 'shared/selectors/app-settings';
import { getCurrentUserEmail } from 'shared/selectors/current-site-user';
import { isSubscriptionEnabled } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/pricing';
import EVENTS from 'shared/constants/events';
import { LinkButton } from 'shared/components/button/button';
import { sides } from 'shared/constants/popout';
import AccountInfoButton from './account-info-button/account-info-button';
import AccountInfoButtonWithTooltip from './account-info-button-with-tooltip/account-button-with-tooltip';
import SubscriptionCancelHandler from 'widget/components/account/account-button/subscription-cancel-handler';
import { withStyles } from 'shared/utils/withStyles';
import styles from './account-button.styl';
import PUB_SUB_RECIPIENTS from 'shared/constants/pub-sub-recipients';
import { withPubSubEvents, consumePubSubEvent, } from 'widget/containers/pub-sub-events';
var AccountButton = /** @class */ (function (_super) {
    __extends(AccountButton, _super);
    function AccountButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleLogoutEvent = function () {
            if (_this.disableConsumeEvents) {
                return;
            }
            _this.props.onLogoutClick();
            _this.disableConsumeEvents = true;
        };
        _this.handleAccountButtonClicked = function () {
            _this.props.onClick();
        };
        return _this;
    }
    AccountButton.prototype.componentDidUpdate = function (prevProps) {
        consumePubSubEvent(EVENTS.SESSION.LOGOUT_USER, this.handleLogoutEvent, {
            prevProps: prevProps,
            nextProps: this.props,
        });
    };
    AccountButton.prototype.renderLoginButton = function () {
        var _a = this.props, className = _a.className, onLoginClick = _a.onLoginClick, isFocusable = _a.isFocusable;
        return (React.createElement("div", { className: className },
            React.createElement(LinkButton, { styleName: "account-button", isFocusable: isFocusable, onClick: onLoginClick, dataHook: "login-button" }, i18n.t('widget.account.sign-in'))));
    };
    AccountButton.prototype.renderAccountButtonWithTooltip = function () {
        var _a = this.props, infoButtonClassName = _a.infoButtonClassName, infoButtonTooltipClassName = _a.infoButtonTooltipClassName, infoButtonPopoutTriangleClassName = _a.infoButtonPopoutTriangleClassName, userEmail = _a.userEmail, tooltipSide = _a.tooltipSide, onLogoutClick = _a.onLogoutClick, isFocusable = _a.isFocusable;
        return (React.createElement(AccountInfoButtonWithTooltip, { className: infoButtonClassName, popoutClassName: infoButtonTooltipClassName, popoutTriangleClassName: infoButtonPopoutTriangleClassName, logoutLabel: i18n.t('widget.account.logout'), tooltipSide: tooltipSide, userEmail: userEmail, isFocusable: isFocusable, onLogoutClick: onLogoutClick, dataHook: "info-button-with-tooltip" }));
    };
    AccountButton.prototype.renderAccountButtonSimple = function () {
        var _a = this.props, infoButtonClassName = _a.infoButtonClassName, isFocusable = _a.isFocusable;
        return (React.createElement(AccountInfoButton, { className: infoButtonClassName, isFocusable: isFocusable, onClick: this.handleAccountButtonClicked }));
    };
    AccountButton.prototype.renderAccountButton = function () {
        var isSubscriptionEnabled = this.props.isSubscriptionEnabled;
        return isSubscriptionEnabled
            ? this.renderAccountButtonSimple()
            : this.renderAccountButtonWithTooltip();
    };
    AccountButton.prototype.render = function () {
        var _a = this.props, className = _a.className, userEmail = _a.userEmail, pubSubRecipientName = _a.pubSubRecipientName, onSubscriptionCancelClick = _a.onSubscriptionCancelClick;
        if (!userEmail) {
            return this.renderLoginButton();
        }
        return (React.createElement("div", { className: classnames('qa-account', className), styleName: "account-info-button-wrapper" },
            onSubscriptionCancelClick && (React.createElement(SubscriptionCancelHandler, { onSubscriptionCancel: onSubscriptionCancelClick, pubSubRecipientName: pubSubRecipientName })),
            this.renderAccountButton()));
    };
    AccountButton.propTypes = {
        className: PropTypes.string,
        infoButtonClassName: PropTypes.string,
        infoButtonTooltipClassName: PropTypes.string,
        infoButtonPopoutTriangleClassName: PropTypes.string,
        appSettings: PropTypes.object,
        userEmail: PropTypes.string,
        onLoginClick: PropTypes.func,
        onLogoutClick: PropTypes.func,
        //click on a simple logout button
        onClick: PropTypes.func,
        onSubscriptionCancelClick: PropTypes.func,
        onSubscribeClick: PropTypes.func,
        tooltipSide: PropTypes.oneOf(_.values(sides)),
        isSubscriptionEnabled: PropTypes.bool,
        isFocusable: PropTypes.bool,
        pubSubRecipientName: PropTypes.oneOf(_.values(PUB_SUB_RECIPIENTS)),
    };
    AccountButton = __decorate([
        withPubSubEvents,
        connect(function (state, ownProps) { return ({
            appSettings: getAllSettings(state),
            userEmail: getCurrentUserEmail(state),
            isSubscriptionEnabled: isSubscriptionEnabled(ownProps.channelData),
        }); }),
        withStyles(styles),
        VODCSSModules(styles)
    ], AccountButton);
    return AccountButton;
}(React.Component));
export default AccountButton;
