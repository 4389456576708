import WixSDK from 'shared/utils/wix-sdk';
export function setPremiumIntentFree() {
    WixSDK.Settings.appEngaged(WixSDK.Settings.PremiumIntent.FREE);
}
export function setPremiumIntentPaid() {
    WixSDK.Settings.appEngaged(WixSDK.Settings.PremiumIntent.PAID);
}
export function updatePremiumIntent(_a) {
    var hasUploadedVideos = _a.hasUploadedVideos;
    if (hasUploadedVideos) {
        return setPremiumIntentPaid();
    }
    return setPremiumIntentFree();
}
