import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { GenericButton } from '../button';
import PropTypes from 'prop-types';
import Icon from 'shared/components/icon/icon';
import { withStyles } from 'shared/utils/withStyles';
import styles from './icon.styl';
var IconButton = /** @class */ (function (_super) {
    __extends(IconButton, _super);
    function IconButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(IconButton.prototype, "label", {
        get: function () {
            var _a = this.props, label = _a.label, children = _a.children;
            var text = _.isUndefined(label) ? children : label;
            if (!text) {
                return null;
            }
            return React.createElement("span", { className: styles.text }, text);
        },
        enumerable: true,
        configurable: true
    });
    IconButton.prototype.render = function () {
        var _a;
        var _b = this.props, Button = _b.Button, name = _b.name, size = _b.size, iconClassName = _b.iconClassName, className = _b.className, dataHook = _b.dataHook, ariaLabel = _b.ariaLabel;
        var props = _.omit(this.props, 'intl', 'styles', 'settings', 'Button', 'name', 'size', 'label', 'children', 'className', 'dataHook', 'iconClassName');
        var label = this.label;
        var classNames = classnames((_a = {},
            _a[styles.generic] = Button === GenericButton,
            _a), className);
        return (React.createElement(Button, __assign({}, props, { ariaLabel: ariaLabel, dataHook: dataHook, className: classNames }),
            React.createElement(Icon, { name: name, size: size, className: classnames(styles.icon, iconClassName) }),
            label));
    };
    IconButton.propTypes = {
        Button: PropTypes.func,
        name: PropTypes.string,
        size: PropTypes.number,
        label: PropTypes.any,
        children: PropTypes.any,
        className: PropTypes.string,
        dataHook: PropTypes.string,
        iconClassName: PropTypes.string,
    };
    IconButton.defaultProps = {
        Button: GenericButton,
    };
    IconButton = __decorate([
        withStyles(styles)
    ], IconButton);
    return IconButton;
}(React.Component));
export default IconButton;
