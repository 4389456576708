import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { loadVODPlayer } from 'shared/utils/load-player';
import { logWidgetSystem } from 'shared/worker/actions/bi';
import { getVideoPreviewUrls } from 'api/public/channel/channel';
import getVidiUrls from 'shared/selectors/vidi-urls';
import { VideoViewMode } from 'playable/dist/src/modules/ui/screen/types';
import { withStyles } from 'shared/utils/withStyles';
import styles from './video-preview.styl';
import { wrapWithContext } from 'shared/utils/context';
var VideoPreview = /** @class */ (function (_super) {
    __extends(VideoPreview, _super);
    function VideoPreview(props) {
        var _this = _super.call(this, props) || this;
        _this.setReady = function () {
            _this.setState({ isReady: true });
        };
        _this.state = {
            vidiUrls: [],
            uploadingError: false,
            VODPlayer: null,
            isReady: false,
        };
        _this.loadUrls();
        return _this;
    }
    VideoPreview.prototype.componentDidMount = function () {
        var _this = this;
        loadVODPlayer().then(function (playerModule) {
            _this.setState({ VODPlayer: playerModule.default });
        });
    };
    VideoPreview.prototype.loadUrls = function () {
        var _this = this;
        var _a = this.props, channelId = _a.channelId, instance = _a.instance;
        this.props.logWidgetSystem('channel.cover.videoPreview.loadUrls.started');
        this.props.getVideoPreviewUrls(channelId, instance).then(function (videoUrls) {
            _this.props.logWidgetSystem('channel.cover.videoPreview.loadUrls.loaded');
            _this.setState({
                vidiUrls: getVidiUrls({ videoUrls: videoUrls }),
                uploadingError: false,
            });
        }, function () {
            _this.props.logWidgetSystem('channel.cover.videoPreview.loadUrls.failed');
            _this.setState({ uploadingError: true });
        });
    };
    VideoPreview.prototype.shouldShowPoster = function () {
        var _a = this.state, uploadingError = _a.uploadingError, isReady = _a.isReady;
        return !isReady || uploadingError;
    };
    VideoPreview.prototype.render = function () {
        var _a;
        var _b = this.props, width = _b.width, height = _b.height, poster = _b.poster;
        var _c = this.state, vidiUrls = _c.vidiUrls, VODPlayer = _c.VODPlayer;
        var posterClassNames = classNames(styles.poster, (_a = {},
            _a[styles.hide] = !this.shouldShowPoster(),
            _a));
        return (React.createElement("div", { className: styles['player-container'] },
            React.createElement("div", { className: posterClassNames }, poster),
            VODPlayer && (React.createElement(VODPlayer, { width: width, height: height, vidiUrls: vidiUrls, onPlay: this.setReady, canShowTitle: false, hideMainUI: true, loader: false, videoViewMode: VideoViewMode.FILL, muted: true, autoPlay: true, loop: true }))));
    };
    VideoPreview.propTypes = {
        channelId: PropTypes.string,
        instance: PropTypes.string,
        poster: PropTypes.node,
        width: PropTypes.number,
        height: PropTypes.number,
    };
    VideoPreview = __decorate([
        connect(null, {
            logWidgetSystem: logWidgetSystem,
            getVideoPreviewUrls: wrapWithContext(getVideoPreviewUrls),
        }),
        withStyles(styles)
    ], VideoPreview);
    return VideoPreview;
}(React.Component));
export default VideoPreview;
